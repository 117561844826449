// App.js - WEB
import React, { Component } from "react";
import { View } from "react-native";
import firebase from 'firebase'
import { connect } from 'react-firebase'

import WebRoutesGenerator from "../../components/src/NativeWebRouteWrapper";
import { ModalContainer } from "react-router-modal";
import HomeScreen from "../../components/src/HomeScreen";
import TopNav from "../../components/src/TopNav";

import InfoPage from '../../blocks/info-page/src/InfoPageBlock'
import AlertBlock from '../../blocks/alert/src/AlertBlock.web'
import VisualAnalytics from "../../blocks/visualanalytics/src/VisualAnalytics";
import CustomisableUserProfiles from "../../blocks/CustomisableUserProfiles/src/CustomisableUserProfiles";
import EmployeeLogin2 from "../../blocks/EmployeeLogin2/src/EmployeeLogin2";
import LikeAPost from "../../blocks/LikeAPost/src/LikeAPost";
import RolesPermissions2 from "../../blocks/RolesPermissions2/src/RolesPermissions2";
import Reviews from "../../blocks/reviews/src/Reviews";
import AddReview from "../../blocks/reviews/src/AddReview";
import PeopleManagement2 from "../../blocks/PeopleManagement2/src/PeopleManagement2";
import FriendList from "../../blocks/FriendList/src/FriendList";
import GroupChat from "../../blocks/GroupChat/src/GroupChat";
import ActivityFeed from "../../blocks/ActivityFeed/src/ActivityFeed";
import PostCreation from "../../blocks/postcreation/src/PostCreation";
import Posts from "../../blocks/postcreation/src/Posts";
import PostDetails from "../../blocks/postcreation/src/PostDetails";
import AdminConsole3 from "../../blocks/AdminConsole3/src/AdminConsole3";
import Settings5 from "../../blocks/Settings5/src/Settings5";
import Categoriessubcategories from "../../blocks/categoriessubcategories/src/Categoriessubcategories";
import AddFriends from "../../blocks/AddFriends/src/AddFriends";
import OutlookIntegration from "../../blocks/OutlookIntegration/src/OutlookIntegration";
import Share from "../../blocks/share/src/Share";
import UploadMedia2 from "../../blocks/UploadMedia2/src/UploadMedia2";
import TeamBuilder from "../../blocks/TeamBuilder/src/TeamBuilder";
import ElasticSearch from "../../blocks/ElasticSearch/src/ElasticSearch";
import LandingPage from "../../blocks/landingpage/src/LandingPage";
import CreateComment from "../../blocks/comments/src/CreateComment";
import EmailNotifications from "../../blocks/EmailNotifications/src/EmailNotifications";



const routeMap = {
VisualAnalytics:{
 component:VisualAnalytics,
path:"/VisualAnalytics"},
CustomisableUserProfiles:{
 component:CustomisableUserProfiles,
path:"/CustomisableUserProfiles"},
EmployeeLogin2:{
 component:EmployeeLogin2,
path:"/EmployeeLogin2"},
LikeAPost:{
 component:LikeAPost,
path:"/LikeAPost"},
RolesPermissions2:{
 component:RolesPermissions2,
path:"/RolesPermissions2"},
Reviews:{
 component:Reviews,
path:"/Reviews"},
AddReview:{
 component:AddReview,
path:"/AddReview"},
PeopleManagement2:{
 component:PeopleManagement2,
path:"/PeopleManagement2"},
FriendList:{
 component:FriendList,
path:"/FriendList"},
GroupChat:{
 component:GroupChat,
path:"/GroupChat"},
ActivityFeed:{
 component:ActivityFeed,
path:"/ActivityFeed"},
PostCreation:{
 component:PostCreation,
path:"/PostCreation"},
Posts:{
 component:Posts,
path:"/Posts"},
PostDetails:{
 component:PostDetails,
path:"/PostDetails"},
AdminConsole3:{
 component:AdminConsole3,
path:"/AdminConsole3"},
Settings5:{
 component:Settings5,
path:"/Settings5"},
Categoriessubcategories:{
 component:Categoriessubcategories,
path:"/Categoriessubcategories"},
AddFriends:{
 component:AddFriends,
path:"/AddFriends"},
OutlookIntegration:{
 component:OutlookIntegration,
path:"/OutlookIntegration"},
Share:{
 component:Share,
path:"/Share"},
UploadMedia2:{
 component:UploadMedia2,
path:"/UploadMedia2"},
TeamBuilder:{
 component:TeamBuilder,
path:"/TeamBuilder"},
ElasticSearch:{
 component:ElasticSearch,
path:"/ElasticSearch"},
LandingPage:{
 component:LandingPage,
path:"/LandingPage"},
Comment:{
 component:Comment,
path:"/Comment"},
CreateComment:{
 component:CreateComment,
path:"/CreateComment"},
EmailNotifications:{
 component:EmailNotifications,
path:"/EmailNotifications"},

  Home: {
    component: HomeScreen,
    path: '/',
    exact: true
  },
  InfoPage: {
    component: InfoPage,
    path: '/InfoPage'
  },

  AlertWeb: {
    component: AlertBlock,
    path: "*/AlertWeb",
    modal: true
  }

};

const firebaseAPI = firebase.initializeApp({
  apiKey: "AIzaSyDgl9aTbKMdRZ9-ijSZRionh3V591gMJl4",
  authDomain: "rnmasterapp-c11e9.firebaseapp.com",
  databaseURL: "https://rnmasterapp-c11e9.firebaseio.com",
  projectId: "rnmasterapp-c11e9",
  storageBucket: "rnmasterapp-c11e9.appspot.com",
  messagingSenderId: "649592030497",
  appId: "1:649592030497:web:7728bee3f2baef208daa60",
  measurementId: "G-FYBCF3Z2W3"
});

class App extends Component {
   
  render() {

    const defaultAnalytics = firebaseAPI.analytics();
    defaultAnalytics.logEvent('APP_Loaded');
    
    return (
      <View style={{ height: '100vh', width: '100vw' }}>
        <TopNav />
        {WebRoutesGenerator({ routeMap })}
        <ModalContainer />
      </View>
    );
  }
}

export default App;